import { DefaultSeoProps } from "next-seo";

const SEO: DefaultSeoProps = {
	titleTemplate: "%s - Yocale",
	defaultTitle: "Yocale",
	description:
		"Online appointment scheduling for this business is available through Yocale. Choose your favorite provider or desired services to book online.",

	openGraph: {
		type: "website",
		locale: "en_US",
		url: "https://yocale-public-profile.netlify.app",
		siteName: "Yocale Public Profile",
	},
	twitter: {
		handle: "@handle",
		site: "@site",
		cardType: "summary_large_image",
	},
};

export default SEO;


    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import type { AppProps } from "next/app";
import { useState } from "react";
import { Roboto } from "@next/font/google";
import { DefaultSeo } from "next-seo";
import { Hydrate, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Toaster } from "react-hot-toast";
import SEO from "seo.config";
import "styles/globals.css";
const roboto = Roboto({
    weight: ["400", "500"],
    subsets: ["latin"],
    variable: "--font-roboto",
    display: "swap",
});
const App = ({ Component, pageProps }: AppProps) => {
    const [queryClient] = useState(() => new QueryClient());
    return (<main className={`${roboto.variable} font-sans`}>
			<QueryClientProvider client={queryClient}>
				<Hydrate state={pageProps.dehydratedState}>
					<DefaultSeo {...SEO}/>
					<Component {...pageProps}/>
					<Toaster position="bottom-right"/>
					<ReactQueryDevtools initialIsOpen={false}/>
				</Hydrate>
			</QueryClientProvider>
		</main>);
};
const __Next_Translate__Page__1920699e2ee__ = App;

    export default __appWithI18n(__Next_Translate__Page__1920699e2ee__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  